.category-container {
  position: relative
}

.category-container>.category-scroll {
  position: absolute;
  height: 50px;
  top: -165px;
}

@media screen and (min-width: 768px) {
  .category-container>.category-scroll {
    top: -100px;
  }
}